export const CLIENT_APP_PREFIX = "client";
export const ADMIN_APP_PREFIX = "admin";
export const VOUCH_REDIRECT_PREFIX = "v/:code";

const admin = () => `/${ADMIN_APP_PREFIX}`;
const users = () => `/${ADMIN_APP_PREFIX}/users`;
const clientQuestions = () => `/${ADMIN_APP_PREFIX}/client/questions`;
const userPoints = () => `/${ADMIN_APP_PREFIX}/points`;
const userConsultantPoints = (id) => `/${ADMIN_APP_PREFIX}/points/user/${id}`;
const userLeaderboard = () => `/${ADMIN_APP_PREFIX}/points/leaderboard`;
const brief = () => `/${CLIENT_APP_PREFIX}/dashboard/brief`;
const clientDashboard = () => `/${CLIENT_APP_PREFIX}/dashboard`;
const editBrief = (id) => `/${CLIENT_APP_PREFIX}/dashboard/brief/${id}`;
const insights = (userType) => `/${userType}/insights`;
const settings = () => `/${CLIENT_APP_PREFIX}/settings`;
const settingPanel = (panel) => `/${CLIENT_APP_PREFIX}/settings/${panel}`;
const clientContacts = () => `/${ADMIN_APP_PREFIX}/client/contacts`;

export const ClientPaths = {
  admin,
  brief,
  dashboard: clientDashboard,
  editBrief,
  insights,
  settings,
  settingPanel,
  users,
  userPoints,
  userConsultantPoints,
  userLeaderboard,
  clientQuestions,
  clientContacts,
};

const createAccount = () => "/create";
const accountCreated = () => "/created";
const consultantDashboard = () => "/dashboard";
const brand = (brandId) => `/brand/${brandId}`;
const viewBrief = (briefId) => `/brief/${briefId}`;
const answerBrief = (briefId) => `/brief/${briefId}/answer`;
const verify = () => "/verify";
const complete = () => "/complete";
const leaderboard = () => "/leaderboard";
const uploadPoints = () => "/upload";

const completeProfile = () => "/complete-profile";
const editProfile = () => "/edit-profile";

export const ConsultantPaths = {
  createAccount,
  accountCreated,
  dashboard: consultantDashboard,
  brand,
  viewBrief,
  verify,
  complete,
  leaderboard,
  uploadPoints,
  completeProfile,
  editProfile,
  answerBrief,
};

export const LOGIN_PREFIX = "login";
export const RESET_PASSWORD_PREFIX = "reset-password";

const resetPassword = (userType, token) => `/${RESET_PASSWORD_PREFIX}/${userType}/${token}`;
const login = () => `/${LOGIN_PREFIX}`;
const loginUserType = (userType) => `/${LOGIN_PREFIX}/${userType}`;

export const AuthPaths = {
  login,
  loginUserType,
  resetPassword,
};
