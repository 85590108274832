import { useLocation } from "react-router-dom";

import { ReactComponent as HomeIcon } from "../../../../assets/home.svg";
import { ReactComponent as InboxIcon } from "../../../../assets/inbox.svg";
import { ReactComponent as CogIcon } from "../../../../assets/cog.svg";
import { ReactComponent as GroupIcon } from "../../../../assets/group.svg";
import { ReactComponent as LeaderboardIcon } from "../../../../assets/globe.svg";
import { ReactComponent as BriefIcon } from "../../../../assets/brief.svg";
import { ReactComponent as UserIcon } from "../../../../assets/user.svg";
import NavButton from "../../../common/NavButton/NavButton";
import { ClientPaths as Paths } from "../../../../constants/paths";
import { UserType } from "../../../../constants/userType";
import useSession from "../../../../hooks/useSession";

import styles from "./LeftNav.module.scss";

const items = {
  [UserType.CLIENT]: {
    [Paths.dashboard()]: HomeIcon,
    [Paths.insights(UserType.CLIENT)]: InboxIcon,
    [Paths.settings()]: CogIcon,
  },
  [UserType.ADMIN]: {
    [Paths.admin()]: HomeIcon,
    [Paths.insights(UserType.ADMIN)]: InboxIcon,
    [Paths.users()]: GroupIcon,
    [Paths.userLeaderboard()]: LeaderboardIcon,
    [Paths.clientQuestions()]: BriefIcon,
    [Paths.clientContacts()]: UserIcon,
  },
};

const LeftNav = () => {
  const { pathname } = useLocation();
  const { userType } = useSession();

  return (
    <div className={`${styles.leftNav} border-right d-flex flex-column bg-white`}>
      <div className="position-sticky d-flex flex-column">
        {Object.keys(items[userType]).map((itemPath, i) => (
          <NavButton
            key={`nav-item-${i}`}
            path={itemPath}
            Icon={items[userType][itemPath]}
            isCurrent={itemPath === pathname}
          />
        ))}
      </div>
    </div>
  );
};

export default LeftNav;
